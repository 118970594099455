import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const ASSISTANT_ID = import.meta.env.VITE_OPENAI_ASSISTANT_ID;

// Add delay based on message count
const getDelayTime = (messageCount: number): number => {
  if (messageCount > 8) return 3000; // 3 seconds
  if (messageCount > 5) return 2000; // 2 seconds
  return 1000; // 1 second
};

export async function chatWithAssistant(messages: Array<{role: string, content: string}>, messageCount: number) {
  try {
    if (!openai.apiKey) {
      throw new Error('OpenAI API key not configured');
    }

    if (!ASSISTANT_ID) {
      throw new Error('Assistant ID not configured');
    }

    // Add artificial delay based on message count
    await new Promise(resolve => setTimeout(resolve, getDelayTime(messageCount)));

    // Create a thread
    const thread = await openai.beta.threads.create();

    // Add context from previous messages (last 5 messages)
    const recentMessages = messages.slice(-5);
    for (const msg of recentMessages) {
      await openai.beta.threads.messages.create(thread.id, {
        role: msg.role as 'user' | 'assistant',
        content: msg.content
      });
    }

    // Run the assistant
    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: ASSISTANT_ID,
    });

    // Wait for the run to complete
    let runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    
    while (runStatus.status !== 'completed') {
      if (runStatus.status === 'failed') {
        throw new Error('Assistant run failed');
      }
      
      if (runStatus.status === 'expired') {
        throw new Error('Assistant run expired');
      }

      await new Promise(resolve => setTimeout(resolve, 1000));
      runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }

    const messages_response = await openai.beta.threads.messages.list(thread.id);
    const lastMessage = messages_response.data[0];

    await openai.beta.threads.del(thread.id);

    if (!lastMessage.content[0]) {
      throw new Error('No response from assistant');
    }
    if (lastMessage.content[0].type !== 'text') {
      throw new Error('Unexpected response type from assistant');
    }
    return {
      message: lastMessage.content[0].text
    };

  } catch (error: unknown) {
    console.error('Error:', error);
    throw error;
  }
} 