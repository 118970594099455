import { ThemeProvider } from '@/components/theme-provider';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from '@/routes';
import { Header } from '@/components/layout/header';
import { Footer } from '@/components/layout/footer';
import { FloatingChatButton } from '@/components/ui/floating-chat-button';
import { Toaster } from '@/components/ui/toaster';

export default function App() {
  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <Router>
        <div className="flex min-h-screen flex-col">
          <Header />
          <main className="flex-1">
            <AppRoutes />
          </main>
          <Footer />
          <FloatingChatButton />
          <Toaster />
        </div>
      </Router>
    </ThemeProvider>
  );
}