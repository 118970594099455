import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageSquare, Mail, Phone } from 'lucide-react';
import { Button } from '@/components/ui/button';

export function BioCardPage() {
  const [isFlipped, setIsFlipped] = useState(false);
  const [showQR, setShowQR] = useState(false);

  const handleSwipe = (_event: unknown, info: { offset: { y: number } }) => {
    if (Math.abs(info.offset.y) > 50) {
      setIsFlipped(!isFlipped);
    }
  };

  const handleTap = () => {
    setShowQR(!showQR);
  };

  const handleWhatsApp = () => {
    window.open('https://wa.me/601156239249', '_blank');
  };

  const handleEmail = () => {
    window.location.href = 'mailto:hi@tanghoong.com';
  };

  const handleChat = () => {
    window.location.href = '/ai-chat';
  };

  return (
    <div className="fixed inset-0 z-50 backdrop-blur-xl bg-gradient-to-b from-blue-900/60 via-purple-900/60 to-indigo-900/80 flex items-center justify-center">
      <div className="min-h-screen w-full flex items-center justify-center bg-gradient-to-br from-blue-950/50 to-purple-950/50 p-4">
        <div className="relative perspective-1000">
          <div className="w-[400px] h-[250px] relative">
            <AnimatePresence mode="wait">
              <motion.div
                key={isFlipped ? 'back' : 'front'}
                className="w-full h-full absolute"
                initial={{ 
                  rotateX: isFlipped ? -90 : 90,
                  opacity: 0 
                }}
                animate={{ 
                  rotateX: 0,
                  opacity: 1
                }}
                exit={{ 
                  rotateX: isFlipped ? 90 : -90,
                  opacity: 0,
                  transition: { duration: 0.2 }
                }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 25,
                  mass: 1.5
                }}
                drag="y"
                dragConstraints={{ top: 0, bottom: 0 }}
                dragElastic={0.2}
                onDragEnd={handleSwipe}
                onClick={handleTap}
                style={{
                  transformOrigin: "center center"
                }}
              >
                <div className="w-full h-full relative">
                  <img 
                    src={isFlipped ? '/card-back.png' : '/card-front.png'}
                    alt={isFlipped ? 'Card Back' : 'Card Front'}
                    className="w-full h-full object-cover rounded-xl shadow-xl"
                    style={{
                      transformOrigin: "center center"
                    }}
                  />
                </div>

                {/* Enhanced Contact Overlay */}
                {showQR && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="absolute inset-0 flex items-center justify-center bg-black/95 rounded backdrop-blur-sm"
                  >
                    <div className="text-center p-4 rounded-2xl backdrop-blur-md w-full max-w-[350px]">
                      <div className="grid grid-cols-2 gap-4">
                        {/* QR Code Section - Left Column */}
                        <div className="bg-white p-2 rounded-xl shadow-lg">
                          <img
                            src="/biocard_qrlink.png"
                            alt="QR Code"
                            className="w-full rounded-lg"
                          />
                        </div>

                        {/* Contact Buttons - Right Column */}
                        <div className="grid content-between gap-2">
                          <Button 
                            variant="outline" 
                            className="w-full bg-green-500/10 hover:bg-green-500/20 border-green-500/50 text-green-500"
                            onClick={handleWhatsApp}
                          >
                            <Phone className="mr-2 h-4 w-4" />
                            WhatsApp
                          </Button>
                          
                          <Button 
                            variant="outline" 
                            className="w-full bg-blue-500/10 hover:bg-blue-500/20 border-blue-500/50 text-blue-500"
                            onClick={handleEmail}
                          >
                            <Mail className="mr-2 h-4 w-4" />
                            Email
                          </Button>
                          
                          <Button 
                            variant="outline" 
                            className="w-full bg-purple-500/10 hover:bg-purple-500/20 border-purple-500/50 text-purple-500"
                            onClick={handleChat}
                          >
                            <MessageSquare className="mr-2 h-4 w-4" />
                            AI Chat
                          </Button>
                        </div>
                      </div>

                      <Button 
                        variant="ghost" 
                        className="mt-4 text-white/80 text-xs hover:text-white"
                        onClick={() => setShowQR(false)}
                      >
                        Back to Card
                      </Button>
                    </div>
                  </motion.div>
                )}
              </motion.div>
            </AnimatePresence>
          </div>

          {/* Visual swipe indicator */}
          <motion.div
            className="absolute left-1/2 -translate-x-1/2 bottom-[-60px] w-1 h-12 rounded-full bg-muted-foreground/20"
            initial={false}
            onClick={() => setIsFlipped(!isFlipped)}
          >
            <motion.div
              className="w-full h-1/3 bg-primary rounded-full"
              animate={{
                y: [0, 16, 0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}
