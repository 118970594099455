import { Bot } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from './button';

export function FloatingChatButton() {
  const navigate = useNavigate();
  const location = useLocation();

  // Don't show the button on the AI chat page
  if (location.pathname === '/ai-chat') {
    return null;
  }

  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0, opacity: 0 }}
      className="fixed bottom-4 right-4 z-50"
    >
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="relative"
      >
        <div className="absolute -top-8 right-0 bg-primary text-white text-sm px-2 py-1 rounded-xl shadow-lg whitespace-nowrap">
          <div className="absolute -bottom-2 right-6 w-4 h-4 bg-primary rotate-45 -z-10"></div>
          <div className="text-xs font-semibold bg-primary z-10 text-white dark:text-black">Ask AI Charlie</div>
        </div>
        <Button
          onClick={() => navigate('/ai-chat')}
          size="lg"
          className="rounded-full h-14 w-14 shadow-lg bg-primary hover:bg-primary/90"
        >
          <motion.div
            animate={{
              rotate: [0, 10, -10, 0],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <Bot className="h-6 w-6" />
          </motion.div>
        </Button>
      </motion.div>
    </motion.div>
  );
} 