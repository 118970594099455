import { useState, useRef, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Send, Bot, User, RotateCcw, Menu, Home, X, Settings2 } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { chatWithAssistant } from '@/services/api';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import ReactMarkdown from 'react-markdown';

type Message = {
  role: 'assistant' | 'user';
  content: string;
};

type UserSession = {
  firstName: string | null;
  messages: Message[];
  blockExpiry: number | null;
  messageCount: number;
  lastMessageTime: number;
};

const RATE_LIMIT = 6; // messages per minute
const BLOCK_DURATION = 600000; // 10 minutes in milliseconds
const SESSION_KEY = 'ai_chat_session';
const SESSION_STORAGE_KEY = 'ai_chat_conversation';

const SUGGESTED_PROMPTS = [
  "Tell me about Charlie's experience",
  "What services does Charlie offer?",
  "How can I collaborate with Charlie?",
  "What are Charlie's key skills?",
  "Show me Charlie's recent projects",
];

const createGreeting = (name: string | null) => ({
  role: 'assistant' as const,
  content: name 
    ? `Hi ${name}! I'm AI Charlie. How can I help you today?`
    : "Hi! Before we start, could you please tell me your name? (You can say 'My name is ...' or 'Call me ...')"
});

export function AIChatPage() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const [messageCount, setMessageCount] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockTimeRemaining, setBlockTimeRemaining] = useState(0);
  const [firstName, setFirstName] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { toast } = useToast();
  const navigate = useNavigate();
  const [showNameDialog, setShowNameDialog] = useState(false);
  const [showSuggestionsDialog, setShowSuggestionsDialog] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);

  // Enhanced session restoration
  useEffect(() => {
    // Always try to load name first
    const sessionData = localStorage.getItem(SESSION_KEY);
    let userName = null;
    if (sessionData) {
      const session: UserSession = JSON.parse(sessionData);
      userName = session.firstName;
      setFirstName(userName);
      
      // Check for block status
      const now = Date.now();
      if (session.blockExpiry && session.blockExpiry > now) {
        setIsBlocked(true);
        setBlockTimeRemaining(session.blockExpiry - now);
      }
      setMessageCount(session.messageCount);
    }

    // Then try to load saved conversation
    const savedConversation = sessionStorage.getItem(SESSION_STORAGE_KEY);
    if (savedConversation) {
      try {
        const parsedConversation = JSON.parse(savedConversation);
        if (Array.isArray(parsedConversation) && parsedConversation.length > 0) {
          // If we have a saved conversation, use it
          setMessages(parsedConversation);
          setShowOptions(false); // Hide options since we're restoring a conversation
          // toast({
          //   title: "Conversation Restored",
          //   description: "Your previous chat has been loaded.",
          // });
        }
      } catch (error) {
        console.error('Error parsing saved conversation:', error);
        sessionStorage.removeItem(SESSION_STORAGE_KEY);
        // If there's an error, fall back to greeting
        setMessages([createGreeting(userName)]);
      }
    } else {
      // If no saved conversation, show greeting
      setMessages([createGreeting(userName)]);
      if (!userName) {
        setShowNameDialog(true);
      }
    }
  }, []);

  // Enhanced cleanup and save
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (messages.length > 0) {
        // Only save if we have messages and they're not just the initial greeting
        if (messages.length > 1 || messages[0].content !== createGreeting(firstName).content) {
          sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(messages));
        }
      }
    };

    // Save on page hide/unload
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pagehide', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pagehide', handleBeforeUnload);
      // Save on component unmount
      handleBeforeUnload();
    };
  }, [messages, firstName]);

  // Handle block countdown
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isBlocked && blockTimeRemaining > 0) {
      timer = setInterval(() => {
        setBlockTimeRemaining(prev => {
          const remaining = prev - 1000;
          if (remaining <= 0) {
            setIsBlocked(false);
            clearInterval(timer);
            // Clear block from storage
            const sessionData = localStorage.getItem(SESSION_KEY);
            if (sessionData) {
              const session: UserSession = JSON.parse(sessionData);
              session.blockExpiry = null;
              localStorage.setItem(SESSION_KEY, JSON.stringify(session));
            }
          }
          return remaining;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isBlocked, blockTimeRemaining]);

  // Enhanced scroll to bottom
  const scrollToBottom = () => {
    if (scrollRef.current) {
      setTimeout(() => {
        scrollRef.current?.scrollTo({
          top: scrollRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }, 100); // Small delay to ensure content is rendered
    }
  };

  // Auto scroll and focus
  useEffect(() => {
    scrollToBottom();
    // if (!isLoading && !isBlocked && inputRef.current) {
    //   inputRef.current.focus();
    // }
  }, [messages, isLoading, showOptions]);

  const handleRateLimit = () => {
    const now = Date.now();
    const sessionData = localStorage.getItem(SESSION_KEY);
    const session: UserSession = sessionData ? JSON.parse(sessionData) : {
      firstName,
      messages,
      blockExpiry: null,
      messageCount: 0,
      lastMessageTime: now
    };

    // Reset message count if last message was more than a minute ago
    if (now - session.lastMessageTime > 60000) {
      session.messageCount = 0;
    }

    session.messageCount++;
    session.lastMessageTime = now;

    if (session.messageCount > RATE_LIMIT) {
      session.blockExpiry = now + BLOCK_DURATION;
      setIsBlocked(true);
      setBlockTimeRemaining(BLOCK_DURATION);
      localStorage.setItem(SESSION_KEY, JSON.stringify(session));
      
      toast({
        variant: "destructive",
        title: "Rate limit exceeded",
        description: `Please wait ${BLOCK_DURATION / 60000} minutes before sending more messages.`,
      });
      return true;
    }

    setMessageCount(session.messageCount);
    localStorage.setItem(SESSION_KEY, JSON.stringify(session));
    return false;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isLoading || isBlocked) return;
    if (handleRateLimit()) return;

    const userMessage = input.trim();
    setInput('');
    setShowOptions(false);
    
    // Check for name in first message if we don't have it
    if (!firstName && messages.length === 1) {
      const nameMatch = userMessage.match(/(?:I am|I'm|name is|call me) ([A-Za-z]{1,10})/i);
      if (nameMatch) {
        const extractedName = nameMatch[1];
        setFirstName(extractedName);
        // Update session with name and greet the user
        const session: UserSession = {
          firstName: extractedName,
          messages: [],
          blockExpiry: null,
          messageCount: 0,
          lastMessageTime: Date.now()
        };
        localStorage.setItem(SESSION_KEY, JSON.stringify(session));
        
        // Add user's message and AI's greeting
        const newMessages = [
          ...messages,
          { role: 'user' as const, content: userMessage },
          { role: 'assistant' as const, content: `Nice to meet you, ${extractedName}! How can I help you today?` }
        ];
        setMessages(newMessages);
        session.messages = newMessages;
        localStorage.setItem(SESSION_KEY, JSON.stringify(session));
        return;
      } else {
        // If no name found in the first message, prompt again
        const newMessages = [
          ...messages,
          { role: 'user' as const, content: userMessage },
          { role: 'assistant' as const, content: "I didn't catch your name. Could you please tell me your name by saying 'My name is ...' or 'Call me ...'? (max 10 characters)" }
        ];
        setMessages(newMessages);
        return;
      }
    }

    const newMessages = [...messages, { role: 'user' as const, content: userMessage }];
    setMessages(newMessages);
    
    setIsLoading(true);
    try {
      const response = await chatWithAssistant(newMessages, messageCount);
      const aiResponse = firstName 
        ? `${firstName}, ${response.message.value}`
        : response.message;
      const updatedMessages = [...newMessages, { role: 'assistant' as const, content: aiResponse }];
      setMessages(updatedMessages as Message[]);
      
      // Update session storage
      const sessionData = localStorage.getItem(SESSION_KEY);
      const session: UserSession = sessionData ? JSON.parse(sessionData) : {
        firstName,
        blockExpiry: null,
        messageCount,
        lastMessageTime: Date.now()
      };
      session.messages = updatedMessages as Message[];
      localStorage.setItem(SESSION_KEY, JSON.stringify(session));
    } catch (error: unknown) {
      setMessages(prev => [...prev, { 
        role: 'assistant' as const, 
        content: error instanceof Error ? error.message : 'Sorry, I encountered an error. Please try again.' 
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePromptClick = (prompt: string) => {
    setInput(prompt);
    setShowOptions(false);
    scrollToBottom();
  };

  const handleRestart = () => {
    toast({
      title: "Chat reset",
      description: "Starting a new conversation...",
    });

    // Create new greeting message
    const newMessages = [createGreeting(firstName)];
    setMessages(newMessages);
    setShowOptions(true);
    setInput('');
    
    // Reset session storage
    sessionStorage.removeItem(SESSION_STORAGE_KEY);
    
    // Reset session data but keep the user name
    const sessionData = localStorage.getItem(SESSION_KEY);
    if (sessionData) {
      const session: UserSession = JSON.parse(sessionData);
      const newSession: UserSession = {
        firstName: session.firstName,
        messages: newMessages,
        blockExpiry: null,
        messageCount: 0,
        lastMessageTime: Date.now()
      };
      localStorage.setItem(SESSION_KEY, JSON.stringify(newSession));
    }

    // Reset message count
    setMessageCount(0);
    
    scrollToBottom();
  };

  const handleNameSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!nameInput.trim() || nameInput.length > 10) return;

    const name = nameInput.trim();
    const isEditing = !!firstName;
    setFirstName(name);
    setShowNameDialog(false);
    setIsEditingName(false);
    setNameInput('');

    // Update session with name
    const session: UserSession = {
      firstName: name,
      messages: messages,
      blockExpiry: null,
      messageCount,
      lastMessageTime: Date.now()
    };
    localStorage.setItem(SESSION_KEY, JSON.stringify(session));

    if (isEditing) {
      // Add system message about name change
      const systemMessage = { 
        role: 'assistant' as const, 
        content: `I'll call you ${name} from now on!` 
      };
      const updatedMessages = [...messages, systemMessage];
      setMessages(updatedMessages);
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(updatedMessages));
      
      toast({
        title: "Name Updated",
        description: `You'll now be addressed as ${name}`,
      });
    } else {
      // Set initial greeting message
      const greetingMessage = { 
        role: 'assistant' as const, 
        content: `Nice to meet you, ${name}! How can I help you today?` 
      };
      setMessages([greetingMessage]);
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify([greetingMessage]));
      
      toast({
        title: "Welcome!",
        description: `Hi ${name}, I'm ready to help you.`,
      });
    }
    scrollToBottom();
  };

  const handleSuggestionClick = async (prompt: string) => {
    setShowSuggestionsDialog(false);
    if (handleRateLimit()) return;

    const userMessage = { role: 'user' as const, content: prompt };
    const newMessages = [...messages, userMessage];
    setMessages(newMessages);
    scrollToBottom();
    
    setIsLoading(true);
    try {
      const response = await chatWithAssistant(newMessages, messageCount);
      const aiResponse = firstName 
        ? `${firstName}, ${response.message}`
        : response.message;
      const updatedMessages = [...newMessages, { role: 'assistant' as const, content: aiResponse }];
      setMessages(updatedMessages as Message[]);
      
      // Update session storage
      const sessionData = localStorage.getItem(SESSION_KEY);
      const session: UserSession = sessionData ? JSON.parse(sessionData) : {
        firstName,
        blockExpiry: null,
        messageCount,
        lastMessageTime: Date.now()
      };
      session.messages = updatedMessages as Message[];
      localStorage.setItem(SESSION_KEY, JSON.stringify(session));
    } catch (error: unknown) {
      setMessages(prev => [...prev, { 
        role: 'assistant' as const, 
        content: error instanceof Error ? error.message : 'Sorry, I encountered an error. Please try again.'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 backdrop-blur-xl bg-background/80"
      >
        <div className="flex flex-col h-full max-w-4xl mx-auto p-4">
          {/* Enhanced Header */}
          <motion.div 
            className="flex items-center justify-between mb-4 p-3 rounded-lg bg-card/50 backdrop-blur-sm shadow-sm"
            initial={{ y: -20 }}
            animate={{ y: 0 }}
          >
            <div className="flex items-center gap-3">
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="h-10 w-10 rounded-full bg-primary/20 flex items-center justify-center"
              >
                <Bot className="h-6 w-6 text-primary" />
              </motion.div>
              <div>
                <h1 className="text-lg font-semibold">AI Charlie</h1>
                {firstName && (
                  <p className="text-sm text-muted-foreground flex items-center gap-2">
                    Chatting with {firstName}
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-6 w-6"
                      onClick={() => {
                        setNameInput(firstName);
                        setIsEditingName(true);
                        setShowNameDialog(true);
                      }}
                    >
                      <Settings2 className="h-3 w-3" />
                    </Button>
                  </p>
                )}
              </div>
            </div>
            <div className="flex gap-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => navigate('/')}
                className="hover:bg-primary/10"
              >
                <Home className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => navigate(-1)}
                className="hover:bg-primary/10"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          </motion.div>

          {/* Chat Area */}
          <ScrollArea className="flex-1 pr-4" ref={scrollRef}>
            <AnimatePresence mode="popLayout">
              <div className="space-y-4">
                {messages.map((message, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2 }}
                    className={`flex items-start gap-3 ${
                      message.role === 'assistant' ? 'justify-start' : 'justify-end'
                    }`}
                  >
                    {message.role === 'assistant' && (
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        className="w-8 h-8 rounded-full bg-primary/20 flex items-center justify-center"
                      >
                        <Bot className="h-5 w-5 text-primary" />
                      </motion.div>
                    )}
                    <motion.div
                      layout
                      className={`rounded-2xl px-4 py-2 max-w-[80%] ${
                        message.role === 'assistant'
                          ? 'bg-card shadow-sm'
                          : 'bg-primary text-primary-foreground'
                      }`}
                    >
                      {message.role === 'assistant' ? (
                        <div className="prose prose-sm dark:prose-invert prose-p:leading-relaxed prose-pre:p-0">
                          <ReactMarkdown
                            components={{
                              // Customize markdown components
                              p: ({ children }) => (
                                <p className="text-sm mb-2 last:mb-0">{children}</p>
                              ),
                              ul: ({ children }) => (
                                <ul className="list-disc list-inside my-2">{children}</ul>
                              ),
                              ol: ({ children }) => (
                                <ol className="list-decimal list-inside my-2">{children}</ol>
                              ),
                              li: ({ children }) => (
                                <li className="text-sm mb-1">{children}</li>
                              ),
                              code: ({ className, children, ...props }) => (
                                <code
                                  className={`${
                                    className?.includes('inline')
                                      ? 'bg-muted px-1.5 py-0.5 rounded text-xs'
                                      : 'block bg-muted p-2 rounded-lg text-xs overflow-x-auto'
                                  } ${className || ''}`}
                                  {...props}
                                >
                                  {children}
                                </code>
                              ),
                              pre: ({ children }) => (
                                <pre className="bg-transparent p-0 my-2">{children}</pre>
                              ),
                              h1: ({ children }) => (
                                <h1 className="text-lg font-semibold mb-2">{children}</h1>
                              ),
                              h2: ({ children }) => (
                                <h2 className="text-base font-semibold mb-2">{children}</h2>
                              ),
                              h3: ({ children }) => (
                                <h3 className="text-sm font-semibold mb-2">{children}</h3>
                              ),
                              a: ({ href, children }) => (
                                <a 
                                  href={href}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-primary hover:underline"
                                >
                                  {children}
                                </a>
                              ),
                            }}
                          >
                            {message.content}
                          </ReactMarkdown>
                        </div>
                      ) : (
                        <p className="text-sm">{message.content}</p>
                      )}
                    </motion.div>
                    {message.role === 'user' && (
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        className="w-8 h-8 rounded-full bg-primary flex items-center justify-center"
                      >
                        <User className="h-5 w-5 text-primary-foreground" />
                      </motion.div>
                    )}
                  </motion.div>
                ))}
                
                {/* Loading indicator */}
                <AnimatePresence>
                  {isLoading && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="flex items-center gap-2 text-muted-foreground"
                    >
                      <div className="animate-spin">
                        <Bot className="h-4 w-4" />
                      </div>
                      <p className="text-sm">Thinking...</p>
                    </motion.div>
                  )}
                </AnimatePresence>

                {/* Suggested Prompts */}
                <AnimatePresence>
                  {showOptions && (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      className="grid gap-2 mt-4"
                    >
                      <p className="text-sm text-muted-foreground">Suggested questions:</p>
                      {SUGGESTED_PROMPTS.map((prompt, index) => (
                        <Button
                          key={index}
                          variant="outline"
                          className="justify-start text-left hover:bg-primary/10"
                          onClick={() => handlePromptClick(prompt)}
                        >
                          {prompt}
                        </Button>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </AnimatePresence>
          </ScrollArea>

          {/* Input Area */}
          <motion.div 
            className="mt-4 space-y-4"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="icon"
                onClick={() => setShowOptions(!showOptions)}
                className="shrink-0 hover:bg-primary/10"
              >
                <Menu className="h-4 w-4" />
              </Button>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    className="shrink-0 hover:bg-primary/10"
                  >
                    <RotateCcw className="h-4 w-4" />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Reset Conversation</AlertDialogTitle>
                    <AlertDialogDescription>
                      This will clear the current conversation. Are you sure?
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={handleRestart}>
                      Reset
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
              <form onSubmit={handleSubmit} className="flex gap-2 flex-1">
                <Input
                  ref={inputRef}
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder={
                    isBlocked 
                      ? `Please wait ${Math.ceil(blockTimeRemaining / 1000)} seconds...` 
                      : "Type your message..."
                  }
                  className="flex-1"
                  disabled={isLoading || isBlocked}
                />
                <Button 
                  type="submit" 
                  disabled={isLoading || isBlocked}
                  className="hover:bg-primary/90"
                >
                  <Send className="h-4 w-4" />
                </Button>
              </form>
            </div>
            <AnimatePresence>
              {isBlocked && (
                <motion.p
                  initial={{ opacity: 0, y: 5 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -5 }}
                  className="text-xs text-destructive text-center"
                >
                  Rate limit exceeded. Please wait before sending more messages.
                </motion.p>
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </motion.div>

      {/* Enhanced Name Dialog */}
      <Dialog 
        open={showNameDialog} 
        onOpenChange={(open) => {
          setShowNameDialog(open);
          if (!open) {
            setIsEditingName(false);
            setNameInput('');
          }
        }}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {isEditingName ? 'Change Your Name' : 'Welcome! What\'s your name?'}
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleNameSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name">Name (max 10 characters)</Label>
              <Input
                id="name"
                value={nameInput}
                onChange={(e) => setNameInput(e.target.value)}
                maxLength={10}
                placeholder="Enter your name"
                autoFocus
                className="transition-all duration-200"
              />
            </div>
            <div className="flex gap-2">
              {isEditingName && (
                <Button 
                  type="button" 
                  variant="outline"
                  className="flex-1"
                  onClick={() => {
                    setShowNameDialog(false);
                    setIsEditingName(false);
                    setNameInput('');
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button 
                type="submit" 
                className="flex-1"
                disabled={!nameInput.trim()}
              >
                {isEditingName ? 'Update Name' : 'Start Chat'}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/* Modified Suggestions Dialog */}
      <Dialog open={showSuggestionsDialog} onOpenChange={setShowSuggestionsDialog}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Choose a Question</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <p className="text-sm text-muted-foreground">
              Select a question or type your own below:
            </p>
            {SUGGESTED_PROMPTS.map((prompt, index) => (
              <Button
                key={index}
                variant="outline"
                className="w-full justify-start text-left h-auto whitespace-normal py-3"
                onClick={() => handleSuggestionClick(prompt)}
              >
                {prompt}
              </Button>
            ))}
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-background px-2 text-muted-foreground">
                  Or
                </span>
              </div>
            </div>
            <form onSubmit={handleSubmit} className="flex gap-2">
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your custom question..."
                className="flex-1"
              />
              <Button 
                type="submit"
                disabled={!input.trim()}
                onClick={() => setShowSuggestionsDialog(false)}
              >
                Ask
              </Button>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
} 